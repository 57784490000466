import { Button } from '../common/button';
import "./documentComments.scss";

const DocumentComments = ({ title, documentComments }) => {
    return (
        <section>
            <h2 className="sr-only">{title}</h2>
            <div className="document-comments">
                {
                    documentComments.map(({ title, background, points }, index) => (
                        <article key={index} className={`document-comments__card document-comments__card_${background}`}>
                            <h3 className="document-comment__title">{title}:</h3>
                            <ol className="document-comment__list">
                                {
                                    points.map((item, index) => <li key={index} className="document-comment__item">{item}</li>)
                                }
                            </ol>
                            <Button 
                                type="button"
                                classes="btn-icon btn-xs document-comments__btn"
                                title="Развернуть на весь экран"
                                leftIcon="icon-border-style"
                            />
                        </article>
                    ))
                }
            </div>
        
        </section>
    )
}

export default DocumentComments;