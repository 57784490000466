import React, { useState } from 'react';
import MainCharts from '../components/mainCharts';
import DocumentComments from '../components/documentComments';
import mockDocumentComments from '../components/documentComments/mockDocumentComments';
import { mockMainChartsLineData, mockMainChartsBarData } from '../components/mainCharts/mockMainCharts';

const DocumentsPage = () => {
    return (
        <>
            <MainCharts 
                title="Графики" 
                lineChartData={mockMainChartsLineData} 
                barChartData={mockMainChartsBarData}
                isShowDownloadBtn
            />
            <DocumentComments title="Комментарии" documentComments={ mockDocumentComments } />
        </>
    )
}

export default DocumentsPage;
