const mockDocumentComments = [
    {
        title: 'Проведено тестирование',
        background: 'blue',
        points: [
            'Взали вот это',
            'Получили отклонения',
            'Связались подтвердили с Петровым П.П.',
            'Вывод вот такой'
        ]
    },
    {
        title: 'Выявлены недостататки',
        background: 'red',
        points: [
            'Недополучена выручка',
            'Завышены затраты'
        ]
    },
    {
        title: 'Мероприятия',
        background: 'orange',
        points: [
            'Создать регламент',
            'Внедрить контроль'
        ]
    },
]

export default mockDocumentComments;